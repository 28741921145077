<template>
  <b-container class="m-0 p-0">
    <img :src="icon" alt="clock" />
    <h4 class="subtitle-form">Temps</h4>
    <div class="text-infos bg-white">
      <!-- Title date -->
      <b-row class="m-0 bg-white">
        <b-col cols="7" class="subtitle-form change-subtitle-color">Date démarrage</b-col>
        <b-col cols="3" class="subtitle-form change-subtitle-color">Date fin</b-col>
      </b-row>

      <!-- Date Différentes -->
      <b-row class="m-0 bg-white" v-if="this.currentEntry.startDate == undefined && this.currentEntry.endDate == undefined" >
        <b-col cols="6" class="text-infos whitout-bg text-time-color">
          {{ $t("activities.multiple.details.date") }}
        </b-col>
        <b-col cols="6" class="text-infos whitout-bg text-time-color">
          {{ $t("activities.multiple.details.date") }}
        </b-col>  
      </b-row>

      <!--Date-->
      <b-row class="m-0 bg-white" v-else>
        <b-col cols="3" class="text-infos whitout-bg text-time-color">
          {{ this.currentEntry.startDate ? formatDate(this.currentEntry.startDate, opt="date") : $t("empty_information")}}
        </b-col>
        <b-col cols="4" class="text-infos whitout-bg text-time-color">
            {{ formatDate(this.currentEntry.startDate, opt="time") }}
          </b-col>
        <b-col cols="3" class="text-infos whitout-bg text-time-color">
          {{ this.currentEntry.endDate ? formatDate(this.currentEntry.endDate, opt="date") : $t("empty_information")}}
        </b-col>
        <b-col cols="2" class="text-infos whitout-bg text-time-color">
            {{ formatDate(this.currentEntry.endDate, opt="time") }}
          </b-col>   
      </b-row>
     

      <!-- Elapsed time -->
      <b-row v-show="this.currentEntry.elapsedTime != null" colspan=2 class="mt-2">
        <b-col cols="10">
            <span class="subtitle-form change-subtitle-color"> {{ $t('activities.details.elapsed_time') }} : </span>
            <span class="text-infos whitout-bg text-time-color">{{ convertSecsToTime(this.currentEntry.elapsedTime, "hm", "h") }}</span>
        </b-col>
      </b-row>

      <!-- Working time -->
      <b-row v-show="this.currentEntry.elapsedTime != null" colspan=2>
        <b-col cols="10"><span class="subtitle-form change-subtitle-color"> {{ $t('activities.details.working_time') }} : </span>
            <span class="text-infos whitout-bg text-time-color">{{ convertSecsToTime(this.currentEntry.workingTime, "hm", "h") }}</span></b-col>
      </b-row>

      <!-- Road duration -->
      <b-row v-show="this.currentEntry.roadDuration != null" colspan=2>
        <b-col cols="10">
            <span class="subtitle-form change-subtitle-color">{{ $t('activities.details.road_duration') }} : </span>
            <span class="text-infos whitout-bg text-time-color">{{ convertSecsToTime(this.currentEntry.roadDuration, "hm", "h") }}</span>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import clock from "@/assets/images/clock.svg";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import {formatDate} from "@/utils/commons";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      icon: clock,
    };
  },
  methods: {
    /**
     * Description: Formate les heures, minutes et secondes en rajoutant un 0 si nécessaire.
     * @param {string}   hours Nombres d'heures.
     * @param {string}   minutes Nombre de minutes.
     * @param {string}   secondes Nombres de secondes.
     * @return {object}  Return un objet contenant les attribut heures, minutes, secondes soit
     * objet["hours"], objet["minutes"], objet["secondes"].
     */
    formatHours: function(hours = "", minutes = "", secondes = "") {
      var clock = {};

      clock["hours"] = hours;
      clock["minutes"] = minutes;
      clock["secondes"] = secondes;

      if (hours.length == 1) clock["hours"] = "0" + hours;
      if (minutes.length == 1) clock["minutes"] = "0" + minutes;
      if (secondes.length == 1) clock["secondes"] = "0" + secondes;

      return clock;
    },
    formatDate,
    /**
     * Description: Convertie des secondes en "heures:minutes:secondes" et renvoie une string
     * "heures:minutes:secondes".
     * @param {number}   secs Nombres de secondes.
     * @param {string}   opt Argument optionnel qui peut être 'h' pour heures, 'm' pour minutes
     * ou 'hm' pour heures et minutes.
     * @return {string}  Par défaut une string "heures:minutes:secondes" sinon heures, minutes ou
     * "heures:minutes" selon l'option passé au paramètre opt.
     */
    convertSecsToTime: function(secs, opt = "", delimiter = ":") {
      if (secs != null) {
        var hours = parseInt(secs / 3600);
        var minutes = parseInt((secs - hours * 3600) / 60);
        var secondes = secs - (3600 * hours + 60 * minutes);

        if (hours < 0) hours = 0;
        if (minutes < 0) minutes = 0;
        if (secondes < 0) secondes = 0;

        var clock = this.formatHours(hours.toString(), minutes.toString(), secondes.toString());

        if (opt == "h") return clock["hours"];
        else if (opt == "m") return clock["minutes"];
        else if (opt == "hm") return clock["hours"] + delimiter + clock["minutes"];
        return clock["hours"] + ":" + clock["minutes"] + ":" + clock["secondes"];
      }

      return this.$t("empty_information");
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Change the subtitle color.
 */
.change-subtitle-color {
  color: $primary-color;
}

img {
  width: 1em;
  float: left;
  margin: auto 5px 5px auto;
}
</style>
